
import React, { Fragment } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
// import Image from "gatsby-image"


function RelatedPosts({ tags, slug }) {
  return (
    <StaticQuery
      query={pageQuery}
      render={({ allSitePage: { edges }}) => {
        // console.log(edges)
        const list = edges.reduce((prev, cur) => {
          if(tags.includes(cur.node.context.tag)){
            //去重
            const filted = cur.node.context.list
              .filter(v => v.slug !== slug && !prev.find(pv => pv.slug === v.slug))
            return prev.concat(filted);
          }
          return prev;
        }, [])

        return (
          <Fragment>
            <h4>相关文章</h4>
            <ul>
              {
                list.map((v, i) => (
                  <li key={i}>
                    <Link to={v.slug} >{v.title}</Link>
                  </li>
                ))
              }
            </ul>
          </Fragment>
        )
      }}
    />
  )
}

const pageQuery = graphql`
query {
  allSitePage(filter: {context: {iam: {eq: "post-tag-list-page"}}}) {
    edges {
      node {
        id
        context {
          list {
            slug
            title
          }
          tag
        }
      }
    }
  }
}
`

export default RelatedPosts
